// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import nprogress from '/home/runner/work/GPT-Vis/GPT-Vis/node_modules/.pnpm/nprogress@0.2.0/node_modules/nprogress';
import './nprogress.css';
import UserLoading from '/home/runner/work/GPT-Vis/GPT-Vis/node_modules/.pnpm/dumi@2.4.17_@babel+core@7.26.0_@swc+helpers@0.5.1_@types+node@22.10.2_@types+react@18.3.18_es_66lgbuo7rvpdvdwnbxjuipsv7q/node_modules/dumi/dist/client/pages/Loading';
import React, { useLayoutEffect, type FC } from 'react';
import { useSiteData } from 'dumi';

const DumiLoading: FC = () => {
  const { setLoading } = useSiteData();

  useLayoutEffect(() => {
    setLoading(true);
    nprogress.start();

    return () => {
      setLoading(false);
      nprogress.done();
    }
  }, []);

  return <UserLoading />
}

export default DumiLoading;
